import '..//styles/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { listPresentations } from '..//graphql/queries';
import { generateClient } from 'aws-amplify/api';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {Tag} from 'primereact/tag';

const client = generateClient();

const VideoTranscribePage = () => {
    
  const [presentations, setPresentations] = useState([]); // Use state for presentations
  const [selectedPresentation, setSelectedPresentation] = useState(null);

    useEffect(() => {
      loadPresentations();
    }, []);

    const truncateFields = (object) => {
      const truncatedObject = {};
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const value = object[key];
          if (typeof value === 'string' && value.length > 50) {
            truncatedObject[key] = value.substring(0, 50) + '...';
          } else {
            truncatedObject[key] = value;
          }
        }
      }
      return truncatedObject;
    };

    function convertUTCToCST(utcString) {
      const date = new Date(utcString);
      return date.toLocaleString('en-US', { timeZone: 'America/Chicago' });
    };

    function emptyTranscriptFixer(transcription){
      if(!transcription){
        return "Loading..."
      }
      return transcription
    }
  

    //Function to load info from presentation endpoint
    const loadPresentations = async () => {
      try {
        const presentationsResponse = await client.graphql({
          query: listPresentations,
          authMode: 'userPool'
        }
      );
      var truncatedPresentations = presentationsResponse.data.listPresentations.items.map(presentation => truncateFields(presentation));
      console.log(truncatedPresentations);

      truncatedPresentations = truncatedPresentations.map(item => ({
        ...item,
        createdAt: convertUTCToCST(item.createdAt)
      }));
      truncatedPresentations = truncatedPresentations.map(item => ({
        ...item,
        transcription: emptyTranscriptFixer(item.rawTranscription)
      }));
      
      setPresentations(truncatedPresentations);
      } 
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    const columns = [
      { field: 'createdAt', header: 'Date' },
      { field: 'transcription', header: 'Transcription' },
      { field: 'name', header: 'File Name' },
      { field : 'id', header: 'ID'}

    ];

    const navigate = useNavigate();
    const onRowSelect = (event) => {
      navigate(`/presentations/${event.data.id}`);
  };

  const statusBodyTemplate = (presentation) => {
    let value;
    if(presentation.transcription === 'Loading...'){
      value = 'Transcribing';
    }
    else{
      value = 'Transcribed'
    }
    return <Tag value={value} severity={getSeverity(presentation)}></Tag>;
  };
  const getSeverity = (presentation) => {
    if(presentation.transcription !== 'Loading...'){
      return 'success';
    }
    return 'danger';
  };




    return (
        <DataTable value={presentations} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} showGridlines stripedRows  selectionMode="single" 
        selection={selectedPresentation} onSelectionChange={(e) => setSelectedPresentation(e.value)} dataKey="id" sortField="createdAt" sortOrder="-1"
        onRowSelect={onRowSelect}  metaKeySelection={false} tableStyle={{ minWidth: '50rem' }}>
          <Column field="createdAt" header="Date" sortable ></Column>
          <Column field="transcription" header = "Transcription" sortable></Column>
          <Column field="name" header="File Name" filter filterPlaceholder="Search by name" sortable></Column>
          <Column field="id" header="ID" sortable></Column>
          <Column header="Status" body={statusBodyTemplate} sortable></Column>
        </DataTable>
      
    );
  };

export default VideoTranscribePage;